import * as React from "react";
import Layout from "../layouts/Layout";
import DefaultButton from "../components/buttons/DefaultButton";
import { StaticImage } from "gatsby-plugin-image";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div className="container my-5">
        <StaticImage
          src="../assets/images/icon.svg"
          className="mx-auto animate__animated animate__hinge animate__delay-1s"
          alt="Prev slide"
          placeholder="blurred"
          layout="fixed"
          width={256}
          height={256}
          objectFit="contain"
        />
        <h1 className="display-1 text-center">Pagina non trovata</h1>
        <DefaultButton
          title="Torna su Homepage"
          className="mt-5 btn-outline-dark d-block mx-auto"
          url="/"
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
